<template lang="pug">
.suitable.wuwow-card
  .wuwow-card-head.text-center
    h4.wuwow-card-title 適性調整
  .wuwow-card-body
    h5.d-inline 教材安排類型
    //- 只有0-1等的學員可以編輯
    .d-inline.ml-5(v-if="vipInfo.page_can_edit")
      b-form-group.d-inline(v-slot='{ ariaDescribedby }')
        b-form-radio.mx-2(v-model='topicType' :aria-describedby='ariaDescribedby' name='some-radios' value=0) 預設
        b-form-radio.mx-2(v-model='topicType' :aria-describedby='ariaDescribedby' name='some-radios' value=2) 客製化
      b-button.w-20.ml-5(variant="primary" @click="submit()") 儲存
    ul
      li.root
        ul
          li(v-for="(topic,index) in interestedTopic" :key="topic.id")
            b-form-checkbox(v-if="topicType == 2 && vipInfo.page_can_edit" v-model="topic.is_on" name="check-button" switch :disabled="!topic.can_edit")
            span.ml-2(:class="(topicType == 2 && !topic.is_on)?'jr-text-dark-color':'jr-home-main-color'") {{topic.title}}
            //- 展開章節細項
            b-button(v-if="topic.sub_topics" variant="link" @click="topic.showSubTopic = !topic.showSubTopic")
              span(v-if="topic.showSubTopic") (-)
              span(v-else) (+)
            ul(v-if="topic.sub_topics && topic.showSubTopic")
              li(v-for="subTopic in topic.sub_topics" :key="subTopic.id") {{subTopic.display_name}}
    .alert-text.alert-secondary(v-show="topicType == 2 && vipInfo.page_can_edit")
      span 注意事項:
      ol.m-0
        li.my-1(v-if="minimumAmount>0") 請至少選取{{minimumAmount}}項主題
        li.my-1 預設/客製化/主題操作完畢後，請務必點選儲存按鈕。
        li.my-1 已進行的教材課綱將無法被關閉。
        li.my-1 等級進階時將會自動為您調整教材課綱為進階後的學習等級。
        li.my-1 客製化功能僅限等級0與等級1學員使用。
        li.my-1 每次操作客製化功能並更新教材選項後，將重新編排教材安排順序。
</template>

<script type="text/javascript">
import VueSwitch from '@/components/switch';
import suitableConstants from '@/constants/suitable';
import httpCodeConstants from '@/constants/httpCode';
import platformConstants from '@/constants/platform.js';
import { alertFailedMessage, alertSuccessMessage } from '@/utils/sweetAlert';
import i18n from '@/views/dojo/account/suitable/lang';
import LioshutanApi from '@/api';
import lodash from 'lodash';

export default {
  name: 'Suitable',

  components: {
    VueSwitch,
  },

  i18n,

  data() {
    return {
      topicType: 0,
      interestedTopic: [],
      switchShowSubTopic: [],
      vipInfo: {},
      minimumAmount: 0,
    };
  },

  created(){
    this.getSuitableList();
  },

  methods: {
    async getSuitableList(){
      try {
        const params = {
          platform: platformConstants.WUWOW_JUNIOR,
        };
        const result = await LioshutanApi.suitable.getSuitableList(params);
        const topics = result.data.data.interested_topics;
        this.interestedTopic = lodash.map(topics, (topic) => {
          topic.showSubTopic = false;
          topic.is_on = (topic.is_on === 1);
          return topic;
        });
        this.vipInfo = result.data.data.vip_info;
        this.topicType = result.data.data.vip_info.material_arrange_type;
        this.minimumAmount = result.data.data.minimum_amount;
      } catch (error) {
        alertFailedMessage(error.message);
      }
    },

    submit(){
      let checkedTopicListId = [];
      let uncheckedTopicListId = [];

      // 定義送出資料格式
      const topicIdList = {
        topicOn: [],
        topicOff: [],
      };

      // 分類勾選資料
      const checkedTopicList = lodash.filter(this.interestedTopic, 'is_on');
      const uncheckedTopicList = lodash.filter(this.interestedTopic, ['is_on', false]);

      // 客製化
      const CUSTOMIZATION = 2;

      if (Number(this.topicType) === CUSTOMIZATION) {
        checkedTopicListId = lodash.map(checkedTopicList, 'id');
        uncheckedTopicListId = lodash.map(uncheckedTopicList, 'id');
        // 驗證送出的適性有沒有大於minimumAmount
        if (checkedTopicListId.length < this.minimumAmount) {
          alertFailedMessage(`請至少選擇${this.minimumAmount}項主題`);
        } else {
          topicIdList.topicOn = checkedTopicListId;
          topicIdList.topicOff = uncheckedTopicListId;
          this.saveSuitableChange(topicIdList);
        }
      } else {
        checkedTopicListId = lodash.map(this.interestedTopic, 'id');
        topicIdList.topicOn = checkedTopicListId;
        this.saveSuitableChange(topicIdList);
      }
    },

    async saveSuitableChange(topicIdList){
      try {
        const params = {
          materialArrangeType: this.topicType,
          topicOn: topicIdList.topicOn,
          topicOff: topicIdList.topicOff,
          platform: platformConstants.WUWOW_JUNIOR,
        };
        const res = await LioshutanApi.suitable.setSuitableList(params);
        if (res.status === httpCodeConstants.SUCCESS){
          alertSuccessMessage(this.$t('setUpSuccess'));
        }
      } catch (error) {
        // 後端驗證選擇幾項適性錯誤訊息
        alertFailedMessage(error.response.data.messages.input_validates);
      }
    },

    getSelectAmountId(interestedTopic){
      // 後端接收所選適性id陣列
      const selectList = [];
      interestedTopic.forEach((element) => {
        if (element.enabled === suitableConstants.SELECTED){
          selectList.push(element.id);
        }
      });
      return selectList;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/views/dojo/account/suitable/suitable";
.suitable {
  min-height: 400px;
}
.alert-text {
  background-color: #EFF4FF;
  border-radius: 17px;
  padding: 15px;
  margin-top: 20px;
}
ul {
  margin: 0px 0px 0px 5px;
  list-style: none;
  line-height: 2em;
  font-family: Arial;

  li {
    font-size: 16px;
    position: relative;

    &:before {
      position: absolute;
      left: -15px;
      top: -3px;
      content: '';
      display: block;
      border-left: 1px solid gray;
      height: 1em;
      border-bottom: 1px solid gray;
      width: 10px;
    }

    &:after {
      position: absolute;
      left: -15px;
      bottom: -7px;
      content: '';
      display: block;
      border-left: 1px solid gray;
      height: 100%;
    }

    &.root {
      margin: 0px 0px 0px -5px;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
::v-deep .custom-control {
  display: inline;
  left: 5px;
}
::v-deep .custom-control-label:before {
  background-color: #FFFFFF;
  cursor: pointer;

}
::v-deep .custom-radio {
  .custom-control-label:after, .custom-control-label:before {
    left: -20px;
  }
}
::v-deep .custom-control-input:checked~.custom-control-label:before {
  background-color: var( --home-main-color );
  border-color: var( --home-main-color );
  // cursor: pointer;
}
::v-deep .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var( --text-dark-color );
  cursor: auto;
}
</style>
