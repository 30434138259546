var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "suitable wuwow-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('h5', {
    staticClass: "d-inline"
  }, [_vm._v("教材安排類型")]), _vm.vipInfo.page_can_edit ? _c('div', {
    staticClass: "d-inline ml-5"
  }, [_c('b-form-group', {
    staticClass: "d-inline",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio', {
          staticClass: "mx-2",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "some-radios",
            "value": "0"
          },
          model: {
            value: _vm.topicType,
            callback: function ($$v) {
              _vm.topicType = $$v;
            },
            expression: "topicType"
          }
        }, [_vm._v("預設")]), _c('b-form-radio', {
          staticClass: "mx-2",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "name": "some-radios",
            "value": "2"
          },
          model: {
            value: _vm.topicType,
            callback: function ($$v) {
              _vm.topicType = $$v;
            },
            expression: "topicType"
          }
        }, [_vm._v("客製化")])];
      }
    }], null, false, 2685221767)
  }), _c('b-button', {
    staticClass: "w-20 ml-5",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("儲存")])], 1) : _vm._e(), _c('ul', [_c('li', {
    staticClass: "root"
  }, [_c('ul', _vm._l(_vm.interestedTopic, function (topic, index) {
    return _c('li', {
      key: topic.id
    }, [_vm.topicType == 2 && _vm.vipInfo.page_can_edit ? _c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": "",
        "disabled": !topic.can_edit
      },
      model: {
        value: topic.is_on,
        callback: function ($$v) {
          _vm.$set(topic, "is_on", $$v);
        },
        expression: "topic.is_on"
      }
    }) : _vm._e(), _c('span', {
      staticClass: "ml-2",
      class: _vm.topicType == 2 && !topic.is_on ? 'jr-text-dark-color' : 'jr-home-main-color'
    }, [_vm._v(_vm._s(topic.title))]), topic.sub_topics ? _c('b-button', {
      attrs: {
        "variant": "link"
      },
      on: {
        "click": function ($event) {
          topic.showSubTopic = !topic.showSubTopic;
        }
      }
    }, [topic.showSubTopic ? _c('span', [_vm._v("(-)")]) : _c('span', [_vm._v("(+)")])]) : _vm._e(), topic.sub_topics && topic.showSubTopic ? _c('ul', _vm._l(topic.sub_topics, function (subTopic) {
      return _c('li', {
        key: subTopic.id
      }, [_vm._v(_vm._s(subTopic.display_name))]);
    }), 0) : _vm._e()], 1);
  }), 0)])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.topicType == 2 && _vm.vipInfo.page_can_edit,
      expression: "topicType == 2 && vipInfo.page_can_edit"
    }],
    staticClass: "alert-text alert-secondary"
  }, [_c('span', [_vm._v("注意事項:")]), _c('ol', {
    staticClass: "m-0"
  }, [_vm.minimumAmount > 0 ? _c('li', {
    staticClass: "my-1"
  }, [_vm._v("請至少選取" + _vm._s(_vm.minimumAmount) + "項主題")]) : _vm._e(), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("預設/客製化/主題操作完畢後，請務必點選儲存按鈕。")]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("已進行的教材課綱將無法被關閉。")]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("等級進階時將會自動為您調整教材課綱為進階後的學習等級。")]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("客製化功能僅限等級0與等級1學員使用。")]), _c('li', {
    staticClass: "my-1"
  }, [_vm._v("每次操作客製化功能並更新教材選項後，將重新編排教材安排順序。")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card-head text-center"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("適性調整")])]);

}]

export { render, staticRenderFns }