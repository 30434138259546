var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    class: _vm.classList
  }, [_c('input', {
    staticClass: "switch-input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "value": _vm.value,
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.handleChange
    }
  }), _vm.isOn ? [_c('span', {
    staticClass: "switch-label",
    attrs: {
      "data-on": _vm.on,
      "data-off": _vm.off
    }
  })] : [_c('span', {
    staticClass: "switch-label"
  })], _c('span', {
    staticClass: "switch-handle"
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }