export default {
  messages: {
    tw: {
      remindMes: '＊提醒您，適性分析需要選取"三個類別以上"唷！',
      verifiedFailMes: '請選擇最少三個適性分析喔！',
      setUpSuccess: '適性分析設定成功',
    },
    en: {
      remindMes: '＊The suitability analysis requires "more than three categories"！',
      verifiedFailMes: 'Please select at least three categories！',
      setUpSuccess: 'Categories set up successful！',
    },
  },
};
